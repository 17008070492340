import { useEffect, useState } from 'react';
import axios from 'axios';

export default function Home() {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    async function hello() {
      try {
        const response = await axios(process.env.REACT_APP_API_URI);
        const data = await response.data;
        
        
        setMessage(Object.keys(data)[0].toString()+" "+data.hello);
      } catch (err) {
        console.log(err);
      }
    }

    hello();
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <p className="text-xl">segment.ge</p>;
  }
  return (
    <div className="m-auto">
      <h1 className="text-2xl capitalize">{message}</h1>
    </div>
  );
}
