import Home from './components/Home';

function App() {
  return (
    <main className="flex flex-col h-screen w-screen">
      <Home />
    </main>
  );
}

export default App;
